body {
  font: 13pt Georgia, "Times New Roman", Times, serif;
  line-height: 1.3;
  background: #fff !important;
  color: #000;
}
.country,
.topbar-center-text,
.topbar-right,
.topbar,
.navigation,
.footer,
.slider-image-container,
.product-controls,
.product-link,
.navigation-product,
.nos-prestations-buttons,
#rendez-vous,
#evenements-pros,
#experience,
.list-prestations,
.h3-title,
.nos-prestations select,
.btn-underline,
.nos-prestations-infos-links,
.experience-item-float {
  display: none !important;
}
#page-content {
  max-width: none;
  display: block;
  flex-direction: initial;
  padding-top: 0;
}
.header,
.header .breadcrumbs,
.header .topbar-center {
  position: relative;
}
.header:before {
  content: none;
}
.header .breadcrumbs {
  top: auto;
  left: auto;
}
.header .breadcrumbs li,
.header .breadcrumbs li:last-child,
.header .breadcrumbs li:after {
  color: #000;
}
.header .breadcrumbs li:after {
  content: ">";
  line-height: 1;
  width: auto;
  height: auto;
}
.slider-section-produit {
  top: 0;
  margin-bottom: 0;
}
h1 {
  font-size: 24pt;
}
h2, .h2-title {
  font-size: 21pt;
  margin-top: 25px;
}
h3, .h3-title, h4, .h4-title {
  font-size: 14pt;
  margin-top: 25px;
  font-family: Georgia, "Times New Roman", Times, serif !important;
}
.slider-section-produit .slider-info-container .product-title,
.slider-section-produit .slider-info-container .product-subtitle {
  font-family: Georgia, "Times New Roman", Times, serif !important;
}
.navigation-product.sticky + .nos-prestations {
  padding-top: 0;
}
.header .topbar-center .logo-header {
  position: relative;
  left: auto;
  top: auto;
  margin: 0 auto;
}
.slider-section-produit .slider-info-container {
  width: 100%;
  padding: 0;
}
.slider-section-produit .slider-image-container ~ .slider-info-container,
.nos-prestations-left,
.nos-prestations-infos {
  width: 100%;
}
.slider-section-produit .slider-image-container ~ .slider-info-container .product-title,
.slider-section-produit .slider-image-container ~ .slider-info-container .product-subtitle {
  text-align: center;
}

.nos-prestations-left .h2-title:first-child {
  margin-top: 0;
}
.slider-section-produit .slider-info-container .h2-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30pt;
}
.nos-prestations {
  padding: 30px 0 0 0;
  display: block;
}
.nos-prestations-infos > li {
  display: block;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #CCC;
}
.print-only {
  display: block;
  font-weight: bold;
  color: #be0081;
  font-size: 18pt;
  text-transform: uppercase;
  margin-top: 50px;
}
.nos-prestations-infos p {
  margin-top: 10px;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.nos-prestations-infos p:first-child {
  margin-top: 0;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.nos-prestations-infos-prices .product-price p {
  font-family: "Open Sans", sans-serif;
}
.nos-prestations .info-prestations {
  margin-top: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: normal;
  font-size: 13pt;
}
.nos-prestations-infos {
  box-shadow: none;
  padding: 0;
}
.nos-prestations-infos-prices > div {
  display: block;
}
.nos-prestations-infos-prices > div .product-price-info:last-child {
  padding-left: 0;
  padding-top: 0;
}
.experience .h2-title {
  margin-left: 0;
}
.product-content-text,
.experience {
  padding: 0;
}
.nos-prestations-infos-prices .product-price p {
  margin: 0;
  padding: 0;
}
.experience-item-product.experience-item .experience-item-infos {
  width: 100%;
  padding: 0;
  display: block;
}
.informations-pratiques {
  padding: 40px 0 0 0;
}
.section-heading {
  display: block;
}
.informations-pratiques .h2-title {
  margin: 0;
}